@use "../../../../assets/styles/shared" as *;

.news-view {
  margin: 0 auto;

  li {
    list-style: disc;
  }

  .publish-date {
    @include font-family(medium);
  }

  .btn-container {
    margin: 0 auto;
    max-width: $contentMaxWidth;
  }

  .article-content {
    margin: 0 auto;
    max-width: $contentMaxWidth;
    padding: 1rem 4rem 3rem;
  }

  .back-btn,.view-all {
    width: 2rem;
    height: 2rem;
    .label {
      top: 2px
    }
  }
}
