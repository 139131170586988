.content-block {
  margin-top: 1.5rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--base) calc(var(--base) * 2);

 /* @include mid-break {
    grid-template-columns: repeat(6, 1fr);
    gap: var(--base) var(--base);
  }*/
}

.column--oneThird {
  grid-column-end: span 4;
}

.column--half {
  grid-column-end: span 6;
}

.column--twoThirds {
  grid-column-end: span 8;
}

.column--full {
  grid-column-end: span 12;
}

.column {
 /* @include mid-break {
    grid-column-end: span 6;
  }

  @include small-break {
    grid-column-end: span 6;
  }*/
  margin: 0 .5rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.link {
  margin-top: var(--base);
}
