@use "shared" as *;

body, html {
  width: 100%;
  margin: 0 auto;
  font-size: 1.15rem;
  overflow-x: hidden;
  direction: ltr;
  --bs-body-font-family: #{$NeueHelvetica-Regular}, #{$font-stack};
  --bs-btn-font-family: #{$NeueHelvetica-Regular}, #{$font-stack};
  --bs-link-color: rgb(59, 85, 230); /* $hue-blue -- (can't set var with var) */
  --bs-link-hover-color: rgb(59, 85, 230);
  --bs-link-disabled-color: #9d9d9d; /* $hue-grey-dark -- (can't set var with var) */
  background-color: $hue-blue-light;
}

#app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}


h1 {
  @include font-family(bold);
  font-size: 2.5rem;
}

h2 {
  @include font-family(bold);
  font-size: 2.5rem;
}

h3 {
  @include font-family(bold);
  font-size: 1.5rem;
}

h4 {
  @include font-family(bold);
  font-size: 1.25rem;
}

header {
  z-index: 1001;
  background-color: transparent;
  h1 {
    font-size: 1.75rem;
  }
}

.btn-underline {
  height: .25rem;
  width: 100%;
  background-color: $hue-blue-underline;
}

/* view */
.view {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $hue-white;
  overflow: hidden;
}

.view-no-breadcrumb {
  top: 13rem;
}

/* borders */
.border-grey-light {
  border: 1px solid $hue-grey-medium !important;
}

.border-grey {
  border: 1px solid $hue-grey !important;
}

.border-medium {
  border-color: $hue-grey-dark !important;
}

.rounded-md {
  border-radius: 1rem;
}

.rounded-md-top {
  border-radius: 1rem 1rem 0 0;
}

.rounded-md-bottom {
  border-radius: 0 0 1rem 1rem;
}

i {
  @include font-family(normal, italic)
}

.button-rounded {
  background-color: $hue-blue !important;
  color: $hue-white;
  text-transform: uppercase;
  padding: .6rem 2rem;
  border-radius: 1.5rem;
  border: 0;
  @include font-family(bold);
  font-size: .7rem;
  letter-spacing: 1px;
}

.ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes rotateUp {
  from {transform: rotate(0deg)}
  to {transform: rotate(-180deg)}
}
@keyframes rotateDown {
  from {transform: rotate(-180deg)}
  to {transform: rotate(0deg)}
}
.max-lines-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;

  p {
    max-height: 5.5rem;
    overflow: clip;
  }
}

b, strong, .bold {
  @include font-family(bold);
}

/* text sizes */
.text-xxs {
  font-size: .5rem !important;
}

.text-xs, .text-xsmall {
  font-size: .7rem !important;
}

.text-smaller {
  font-size: .85rem !important;
  line-height: .9rem !important;
}

.text-small {
  font-size: .8rem !important;
  line-height: 1rem !important;
}

.text-xnormal {
  font-size: .95rem !important;
  line-height: 1.2rem !important;
}

.text-normal {
  font-size: 1rem !important;
  line-height: 1.2rem !important;
}

.text-large {
  font-size: 1.1rem !important;
  line-height: 1.3rem !important;
}

.text-larger {
  font-size: 1.2rem !important;
  line-height: 1.4rem !important;
}

.btn-link {
  text-decoration-color: $hue-blue-underline !important;
  color: $hue-black !important;

  &:hover {
    box-shadow: none !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    text-decoration-color: $hue-blue-underline !important;
  }

  &:focus, &:active {
    background-color: transparent !important;
    box-shadow: none !important;
    text-decoration: underline !important;
    text-decoration-color: $hue-blue-underline !important;
  }
}


/* text colors */
.text-grey-dark {
  color: $hue-grey-dark !important;
}

.text-grey-darker {
  color: $hue-grey-darker !important;
}

.text-grey-darkest {
  color: $hue-grey-darkest !important;
}

.text-grey {
  color: $hue-grey !important;
}

.text-nav-blue {
  color: $hue-blue-medium2  !important;
}

/* backgrounds */
.bg-blue-med {
  background-color:$hue-blue-medium;
}

.bg-light-blue {
  background-color: $hue-blue-light;
}

.bg-grey-1 {
  background-color: $hue-grey-1;
}

.bg-grey-dark {
  background-color: $hue-grey-dark;
}

.bg-grey-lighter {
  background-color: $hue-grey-lighter !important;
}

.bg-grey-medium {
  background-color: $hue-grey-medium !important;
}

.text-grey {
  color: $hue-grey-text !important;
}

/* widths */
.display-6-5 {
  font-size: 1.5rem;
}

/* toasts */
.toast-container {
  position: fixed !important;
}


.toast  .toast-header  {
  font-size: 1.1rem;
  background-clip: unset;
  border-bottom-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-bottom-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));

  button {
    float: right;
    position: absolute;
    right: 1rem;
    top: .5rem;
  }

  p {
    max-width: 300px;
  }
}

/* columns */
.col-1-5 {
  flex: 0 0 auto !important;
  width: 15.66666667% !important;
}

.col-2-5 {
  flex: 0 0 auto !important;
  width: 20% !important;
}

.col-7-5 {
  flex: 0 0 auto;
  width: calc(66.66666667% + 1%);
}

.col-9-5 {
  flex: 0 0 auto !important;
  width: calc(82%) !important;
}

/* misc */
.btn-hover-grey-md {
  &:hover {
    background-color: $hue-grey-medium !important;
  }
}

.container {
  @media (min-width: 1400px) {
    max-width: 1430px !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

.px-4-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.toast-compact {
  .toast-header {
    position: relative;

    .btn-close {
      position: absolute;
      top: .5rem;
      right: 1rem;
      height: 0.25rem;
      width: 0.25rem;
    }
  }
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.show {
  display: block !important;
}

.not-visible {
  visibility: hidden;
}

/* buttons */
.btn-transparent {
  background-color: transparent !important;
}

.link-underline-none {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
  &:focus, &:active {
    text-decoration: none !important;
  }
}

.btn-icon-only {
  padding: 0 !important;
  background-color: transparent !important;
  width: fit-content !important;
  height: fit-content !important;
  border: none !important;

  &:hover {
    background-color: transparent !important;
    i {
      color: $hue-black !important;
    }

  }

  &:focus, &:active {
    background-color: transparent !important;
    box-shadow: none !important;
    i {
      color: $hue-black !important;
    }
  }
}

.container {
  @include media-breakpoint-down(xxl) {
    max-width: 100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.dropdown-menu {
  min-width: 8rem;

  a {
    padding: .5rem;
    color: var(--bs-navbar-color) !important;

    &:hover {
      background-color: $hue-grey-lighter !important;
    }

    &:disabled {
      background-color: rgba(255, 255, 255, 0.55);
      color: var(--bs-navbar-disabled-color) !important;
    }
  }

  .dropdown-item.active {
    background-color: $hue-white !important;
    @include font-family(bold)
  }

  &[data-bs-popper] {
    left: -2.1rem;
  }
}

.dropdown-button-light {
  color: var(--bs-navbar-color) !important;
  @extend .text-xnormal !optional;

  &:focus, &:active {
    box-shadow: none !important;
  }

  &:hover {
    color: var(--bs-navbar-hover-color) !important;
  }
}


.arrow-container {
  position: relative;
}

.arrow-down {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 6px);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;

  &.disabled {
    border-color: $hue-grey;
  }
  &.small {
    padding: 4px;
    top: calc(50% - 12px);
    left: calc(50% - 2px);
  }
  &.bold {
    border-width: 0 3px 3px 0;
  }
}

.arrow-up {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 6px);
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);

  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;

  &.disabled {
    border-color: $hue-grey;
  }

  &.small {
    padding: 4px;
    top: calc(50% - 7px);
    left: calc(50% - 2px);
  }

  &.bold {
    border-width: 0 3px 3px 0;
  }
}
