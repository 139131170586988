@use "../../../assets/styles/shared" as *;


.viewer-media {


  .media-iframe.website {
    width:1024px;
    height:1020px;
    transform: scale(0.785);
    transform-origin: top left;
  }

   &.panel-open {
     display: flex;
     justify-content: center;

     .media-iframe.website {
       transform: none;
       height:$sz-tools-panel-height;
     }
   }

  .spinner-container {
    margin-top: 4rem;
    left: calc(50% - 2rem);
    position: absolute;
    background-color: $hue-grey-medium;

    .lds-spinner div:after {
      background-color: $hue-white;
    }
  }
}
