@use "../../assets/styles/shared" as *;

.settings-view {

  .create-alert {
    display: none !important;
  }

  .navbar {
    .active_select {
      background-color: $nav-underline;
      height: 2rem;
      width: .3rem;
      position: absolute;
      bottom: 0;
    }

    p {
      @include font-family(light);
      font-size: 1.1rem;
      line-height: 1.4rem;
    }

    a {
      padding-top: .4rem !important;
      padding-bottom: .4rem !important;
      padding-left: 1rem !important;

      &.active {
        @include font-family(bold);
      }
    }
  }

  .page-container, .content{
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .content {
    border: 1px solid $hue-grey;
  }

  /* small tablet and up */
  @include media-breakpoint-up(md) {
    .page-container, .content{
      padding-right: 2rem !important;;
      padding-left: 2rem !important;;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 0;

    .switch {
      margin-right: 1.5rem;
    }
    .navbar-toggler {
      margin-left: .5rem;
    }
    .settings-general {
      border-radius: 0 !important;
      border: 0 !important;
      border-top: 1px solid $hue-grey !important;
    }
    .navbar-collapse {
      background-color: $hue-white;
      border: 1px solid $hue-grey;
      border-radius: 1rem;
      padding: .5rem 0;
      margin-top: .4rem;
    }

    .active_select {
      display: none;
    }
  }
}
