@use "../../../assets/styles/shared" as *;
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.report-issue-modal {

  left: 50% !important;
  transform: translate(-50%, 0);

  .modal-dialog {
    --bs-modal-width: 80%;
    max-width: 500px;
  }

 #dropdown-issue-toggle:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
  }


  @include media-breakpoint-down(md) {
    .lastname, .firstname {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .issue-dropdown-col {
      padding-right: 0 !important;
    }
  }
}



