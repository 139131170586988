@use "../../../assets/styles/shared" as *;

.filters-filter {
  .accordion-button:not(.collapsed) {
    color: $hue-black !important;
  }

  .more-less-link {
    margin-top: -8px;
  }
}
