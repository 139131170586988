@use "../../../assets/styles/shared" as *;

.search-advanced {
  margin-left: 5.8rem !important;
  width: calc(100% - 5.8rem) !important;
  top: -2px;

  .adv-search-btn{
    right: 0;
    top: -2.35rem;
    z-index: 2;
  }

  .underline {
    height: 1px;
    width: calc(100% - 1rem);
    position: absolute;
    background-color: $hue-grey;
  }

  .checkbox {
    input {
      margin-top: 2px;
      cursor: pointer !important;
    }
  }

  .input-group {
    .underline {
      z-index: 1000;
      bottom: 5px;
      left: 12px;
      width: calc(100% - 18px);
    }
  }

  .constraint-row > div {
    padding: 0 .1rem;
  }

  .col-1 {
    width: 11% !important;
  }

  .col-2 {
    width: 19%
  }

  .col-min {
    width: 2rem;
  }

  .bi-x::before {
    font-size: 1.5rem;

    vertical-align: -6px;
  }

  @include media-breakpoint-down(lg) {
    margin-left: .75rem !important;
    width: calc(100% - 1.5rem) !important;

    .adv-search-btn{
      top: -6rem;
    }

    .col {
      flex: none;
      margin-bottom: .75rem;
    }

    .date-ranges {
      max-width: 20rem;
    }

    .categories {
      .checkbox {
        display: block;
        margin: .3rem 0;
      }
    }
  }

}
