@use "../../../../../assets/styles/shared" as *;

.post-layout{
  margin: 0 auto;

  .tag {
    &:first-child {
      margin-left: 0 !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }

  .bg-all-industries {
    background-color:$pastel-pink;
  }

  .bg-tobacco {
    background-color:$pastel-purple;
  }

  .bg-opioids {
    background-color:$pastel-orange;
  }

  .bg-chemical {
    background-color:$pastel-green;
  }

  .bg-drug {
    background-color:$pastel-beige;
  }

  .bg-food {
    background-color:$pastel-turquoise;
  }

  .bg-fossil-fuel {
    background-color:$pastel-blue;
  }
}
