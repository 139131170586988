@use "../../../../../assets/styles/shared" as *;

.iframe-block {
  //max-width: 60rem;
  max-width: 65rem;
  margin: 0 auto;

  .inner {
    width: fit-content;
  }

  .align-left {
    margin: 0
  }

  .align-center {
    margin: 0 auto;
  }

  .align-right {
    margin: 0 0 0 auto;
  }
}
