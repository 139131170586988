@use "../../../assets/styles/shared" as *;

.email-modal {
  .modal-dialog {
    --bs-modal-width: 80%;
    max-width: 800px;
  }

  .modal-footer {
    text-align: right;
    display: block !important;

    .recaptcha {
      display: flex;
      justify-content: flex-end;
      margin-bottom: .5rem;
      margin-top: .5rem;
    }

    .recaptcha-error {
      display: none;
      margin-bottom: .5rem;
      margin-top: -.5rem;
      &.invalid {
        display: block;
      }
    }
  }
}
