@use "../../../assets/styles/shared" as *;

.viewer-card {

  .confidential {
    .caption {
      font-size: .45rem !important;
    }
  }
}
