@use "../../../../assets/styles/shared" as *;

.my-library-menu {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: 1px solid $hue-grey;
  border-top: 0;

  .active {
    @include font-family(bold)
  }

  .active_select {
    background-color: $nav-underline;
    height: 4px;
    margin: 0 1.5rem;
  }

  .nav-toggle {
    display: none !important;
  }

  nav {
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    .nav-container{
      border-radius: 1rem;
      overflow: hidden;
    }

    .navbar {
      display: block;
    }

    .navbar-nav {
      width: 100%;
    }
  }

  @include media-breakpoint-down(lg) {
    border-radius: 0;

    .nav-item {
      text-align: center;
      border-bottom: 1px solid $hue-grey;
      .active_select {
        display: none;
      }
    }

    .subheader-container {
      position: relative;
      width: 100%;

      border-bottom: 1px solid $hue-grey-darker;
      box-shadow: -1px 0px 9px 2px rgba(169,169,169,0.8);
      -webkit-box-shadow: -1px 0px 9px 2px rgba(169,169,169,0.8);
      -moz-box-shadow: -1px 0px 9px 2px rgba(169,169,169,0.8);

      .view-subheading {
        display: inline-block;
        width: calc(100% - 4rem) !important;
      }

      .nav-toggle{

        position: absolute;
        top: -3.6rem; /* show focus shadow */
        right: -1px;
        bottom: 0;

        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, .5) !important;
        }

        background-color: $hue-grey-lighter;
        display: inline-block !important;
        border: 0;
        border-radius: 0;
        width: calc(4rem - 2px);

      }
    }

    @include media-breakpoint-down(lg) {
      .subheader-container .nav-toggle{
        top: -4rem;
      }
    }
  }
}

