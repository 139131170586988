@use "../../../assets/styles/shared" as *;

.header-feedback {
  height: 4.3rem;
  position: relative;

  .content-text {
    width: calc(100% - 2rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-down(md) {
    .content-text {
      font-size: .8rem;
    }
  }
}
