@use "../../../assets/styles/shared" as *;

.viewer-toast {

  &.bg-success {
    background-color: $hue-success-bg !important;
    border-color: $hue-success-border !important;

    .toast-header {
      background-color: $hue-success-bg !important;
      button {
        color: $hue-success-text !important;
      }
    }
    .toast-body {
      color: $hue-success-text !important;
    }
  }

  .toast-header {
    position: relative;

    .btn-close {
      position: absolute;
      top: .5rem;
      right: 1rem;
      height: 0.4rem;
      width: 0.4rem;
    }
  }
}
