@use "../../../../assets/styles/shared" as *;

.back-menu {
  padding: .25rem;

  @include media-breakpoint-down(lg) {
    //background-color: $hue-grey-lighter;
    margin-left: -.5rem;
  }
}
