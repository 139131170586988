@use "../../../assets/styles/shared" as *;

.reset-password {
  .cancel-btn {
    width: 25%
  }

  .reset-btn {
    width: calc(75% - 1rem);
    margin-left: 1rem
  }
}
