@use "../../../../assets/styles/shared" as *;

.date-picker {

  .bi-x::before {
    font-size: 1.2rem !important;
  }

  .calendar-container {
    z-index: 1000;
    left: -7.25rem;
  }

  .react-calendar {
    border-radius: .3rem;
    border-color: $hue-grey-medium;
    border-top: 0;
  }

  .clear-btn {
    right: 1rem;
  }

  .field-container{
    margin-top: 2px;
  }

  .arrow-right {
    margin-top: .5rem;
  }

}
