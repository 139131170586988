@use "../../../assets/styles/shared" as *;

.viewer-document {
  .toast {
    position: relative;
    z-index: 100;
    width: 20rem;
    //margin-top: 4rem;
  }

  .restricted-toast, .native-toast {
    .toast {
      position: absolute;
      left: calc(50% + 5rem);
      //top: 23rem;
    }
  }
}
