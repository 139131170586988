@use "../../../../assets/styles/shared" as *;

.preview-view {
  height: 98vh;
  overflow-y: scroll;

  background-color: $hue-white;
  margin: .5rem 1rem;
  border-radius: 1rem;
  border: 1px solid $hue-grey;


  .news-component {
    padding: 2rem;
  }
}
