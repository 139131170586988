

$NeueHelvetica-UltraLight: 'NeueHelvetica-UltraLight';
$NeueHelvetica-UltraLight_Italic: 'NeueHelvetica-UltraLight_Italic';
$NeueHelvetica-Thin: 'NeueHelvetica-Thin';
$NeueHelvetica-Thin_Italic: 'NeueHelvetica-Thin_Italic';
$NeueHelvetica-Light: 'NeueHelvetica-Light';
$NeueHelvetica-Light_Italic: 'NeueHelvetica-Light_Italic';
$NeueHelvetica-Regular: 'NeueHelvetica-Regular';
$NeueHelvetica-Regular_Italic: 'NeueHelvetica-Regular_Italic';
$NeueHelvetica-Medium: 'NeueHelvetica-Medium';
$NeueHelvetica-Medium_Italic: 'NeueHelvetica-Medium_Italic';
$NeueHelvetica-Bold: 'NeueHelvetica-Bold';
$NeueHelvetica-Bold_Italic: 'NeueHelvetica-Bold_Italic';
$NeueHelvetica-Heavy: 'NeueHelvetica-Heavy';
$NeueHelvetica-Heavy_Italic: 'NeueHelvetica-Heavy_Italic';
$NeueHelvetica-Black: 'NeueHelvetica-Black';
$NeueHelvetica-Black_Italic: 'NeueHelvetica-Black_Italic';

$default-font: $NeueHelvetica-Regular;
$font-stack: Arial, sans-serif;
$font-stack-alt: Arial, sans-serif;

@font-face {
  font-family: $NeueHelvetica-UltraLight;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro25UltraLight/font.woff2') format('woff2'),
        url('../../../public/assets/fonts/NeueHelveticaPro25UltraLight/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-UltraLight_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro26UltraLightItalic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro26UltraLightItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Thin;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro35Thin/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro35Thin/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Thin_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro36ThinItalic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro36ThinItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Light;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro45Light/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro45Light/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Light_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro46LightItalic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro46LightItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Regular;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro55Roman/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro55Roman/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Regular_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro56Italic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro56Italic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Medium;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro65Medium/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro65Medium/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Medium_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro66MediumItalic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro66MediumItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Bold;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro75Bold/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro75Bold/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Bold_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro76BoldItalic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro76BoldItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Heavy;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro85Heavy/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro85Heavy/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Heavy_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro86HeavyItalic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro86HeavyItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Black;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro95Black/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro95Black/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $NeueHelvetica-Black_Italic;
  src:  url('../../../public/assets/fonts/NeueHelveticaPro96BlackItalic/font.woff2') format('woff2'),
  url('../../../public/assets/fonts/NeueHelveticaPro96BlackItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Font Mixins
@mixin font-family($weight: regular, $style: normal, $name: $default-font) {
  @if $name == $default-font {
    @if $weight == ultralight {
      @if $style == italic {
        font-family: #{$NeueHelvetica-UltraLight_Italic}, #{$font-stack};
      } @else {
        font-family: #{$NeueHelvetica-UltraLight}, #{$font-stack};
      }
    } @else if $weight == light {
        @if $style == italic {
          font-family: #{$NeueHelvetica-Light_Italic}, #{$font-stack};
        } @else {
          font-family: #{$NeueHelvetica-Light}, #{$font-stack};
        }
    } @else if $weight == thin {
        @if $style == italic {
          font-family: #{$NeueHelvetica-Thin_Italic}, #{$font-stack};
        } @else {
          font-family: #{$NeueHelvetica-Thin}, #{$font-stack};
        }
    } @else if $weight == medium {
        @if $style == italic {
          font-family: #{$NeueHelvetica-Medium_Italic}, #{$font-stack};
        } @else {
          font-family: #{$NeueHelvetica-Medium}, #{$font-stack};
        }
    } @else if $weight == bold {
        @if $style == italic {
          font-family: #{$NeueHelvetica-Bold_Italic}, #{$font-stack};
        } @else {
          font-family: #{$NeueHelvetica-Bold}, #{$font-stack};
        }
    } @else if $weight == heavy {
        @if $style == italic {
          font-family: #{$NeueHelvetica-Heavy_Italic}, #{$font-stack};
        } @else {
          font-family: #{$NeueHelvetica-Heavy}, #{$font-stack};
        }
    } @else if $weight == black {
        @if $style == italic {
          font-family: #{$NeueHelvetica-Black_Italic}, #{$font-stack};
        } @else {
          font-family: #{$NeueHelvetica-Black}, #{$font-stack};
        }
    } @else {
      @if $style == italic {
        font-family: #{$NeueHelvetica-Regular_Italic}, #{$font-stack};
      } @else {
        font-family: #{$NeueHelvetica-Regular}, #{$font-stack};
      }
    }
    font-weight: normal;
  }
}

.font-ultra-light {
  @include font-family(ultralight);
}

.font-light {
  @include font-family(light);
}

.font-thin {
  @include font-family(thin);
}

.font-medium {
  @include font-family(medium);
}

.font-regular {
  @include font-family(regular);
}

.font-bold {
  @include font-family(bold);
}

.font-heavy {
  @include font-family(heavy);
}

.font-black {
  @include font-family(black);
}
