@use "../../assets/styles/shared" as *;

.switch {
  padding-left: 1.5rem;

  .form-check {
    position: relative;
    padding: 0;
  }

  label {
    color: $hue-white;
    position: absolute;
    left: 1.5rem;
    top: .4rem;
  }

  &.active {
    label {
      left: -.85rem;
    }
  }

  .form-switch .form-check-input {
    width: 5rem;
    height: 2.3rem;
    background-color: $hue-brown-2;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAzMCAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMwIiBoZWlnaHQ9IjI2LjQ5MDkiIHJ4PSIxMy4yNDU0IiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K') !important;
    &:checked {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAzMCAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMwIiBoZWlnaHQ9IjI2LjQ5MDkiIHJ4PSIxMy4yNDU0IiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K') !important;
    }
  }
}
