@use "../../assets/styles/shared" as *;

.industries {
  .view-scroll-container {
    top: 15rem;
  }
  .partners {
    max-height: 3rem;

    .vertical-hr {
      height: 3.5rem;
      width: 1px;
      background-color: $hue-grey-darkest;
    }
  }
  @include media-breakpoint-up(md) {
    .partners {
      margin-left: 3rem;
    }
  }
}
