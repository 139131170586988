@use "../../../../assets/styles/shared" as *;

.page-tools-kebab {
  .dropdown-toggle:after {
    content: none
  }
  .bi-tag::before {
    position: relative;
    top: 4px;
    left: -2px;
  }
  .subtext {
    margin-top: -2px !important;
  }
}
