@use "../../../../assets/styles/shared" as *;

.search-history {
  max-width: 100%;
  .industry-segment {
    &:last-child {
      hr {
        display: none;
      }
    }
  }

  i {
    margin-left: -2px;
  }
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input {
    position: relative;
    z-index: 1000;
  }

  .dropdown-toggle::after {
    margin-left: 0 !important;
  }

  .dropdown-menu {
    z-index: 1;
    inset: 0 0 auto 0 !important;
    transform: translate3d(0, 50px, 0px) !important;
    margin: 0 2.5rem 0 6rem;
  }

  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      z-index: 1;
      inset: 0 0 auto 0 !important;
      transform: translate3d(0px, 50px, 0px) !important;
      margin: 0;
    }
    .results .col {
      padding: 0 !important;
    }
  }
}
