@use "../../../assets/styles/shared" as *;

.view-scroll-container {
  position: absolute;
  top: 14rem;
  overflow-y: scroll;
  left: 0;
  bottom: 0;
  right: 0;

  &.fullscreen {
    top: -.5rem;
    .industry-navbar {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    top: 13rem;
  }

  @include media-breakpoint-down(sm) {


  }
}
