@use "../../../../assets/styles/shared" as *;

.settings-general{

  .notifications {
    .switches .switch .form-switch .form-check-input {
      background-image: url("data:image/svg+xml,%3Csvg width='30' height='27' viewBox='0 0 30 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.254578' width='30' height='26.4909' rx='13.2454' fill='white'/%3E%3Cpath d='M17.3292 22.8389C16.9399 23.6559 16.059 24.2454 15.0022 24.2454C13.9454 24.2454 13.0645 23.6559 12.6752 22.8389H17.3292Z' fill='%23616161' stroke='%23616161'/%3E%3Cpath d='M22.1934 20.9393H7.81113C8.46064 19.5709 9.51967 16.9274 9.51967 14.2557C9.51967 13.6001 9.45843 12.9039 9.34736 12.1865L9.34699 12.1842C9.11449 10.728 9.52766 9.29121 10.5197 8.1303L22.1934 20.9393ZM22.1934 20.9393C21.5499 19.5716 20.4893 16.9216 20.4893 14.2557C20.4893 13.6024 20.5456 12.9019 20.6572 12.186L20.6573 12.1857C20.8861 10.7226 20.4758 9.28584 19.4852 8.13084L19.4848 8.1303C18.7068 7.2206 17.6488 6.58984 16.4577 6.29831L15.6372 6.09749L15.7928 5.92778C15.5613 6.04685 15.2996 6.11548 15.0224 6.11918L15.0157 6.11927V6.11923H14.9888H14.9821L14.9821 6.11918C14.7008 6.11543 14.4355 6.04482 14.2015 5.9225L14.3633 6.10131L13.5451 6.2987C12.3564 6.58549 11.2985 7.21972 10.5198 8.13017L22.1934 20.9393Z' fill='white' stroke='%23616161'/%3E%3C/svg%3E%0A") !important;
      &:checked {
        background-color: $hue-blue;
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='27' viewBox='0 0 30 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.254578' width='30' height='26.4909' rx='13.2454' fill='white'/%3E%3Cpath d='M17.3292 22.8389C16.9399 23.6559 16.059 24.2454 15.0022 24.2454C13.9454 24.2454 13.0645 23.6559 12.6752 22.8389H17.3292Z' fill='%23616161' stroke='%23616161'/%3E%3Cpath d='M22.1934 20.9393H7.81113C8.46064 19.5709 9.51967 16.9274 9.51967 14.2557C9.51967 13.6001 9.45843 12.9039 9.34736 12.1865L9.34699 12.1842C9.11449 10.728 9.52766 9.29121 10.5197 8.1303L22.1934 20.9393ZM22.1934 20.9393C21.5499 19.5716 20.4893 16.9216 20.4893 14.2557C20.4893 13.6024 20.5456 12.9019 20.6572 12.186L20.6573 12.1857C20.8861 10.7226 20.4758 9.28584 19.4852 8.13084L19.4848 8.1303C18.7068 7.2206 17.6488 6.58984 16.4577 6.29831L15.6372 6.09749L15.7928 5.92778C15.5613 6.04685 15.2996 6.11548 15.0224 6.11918L15.0157 6.11927V6.11923H14.9888H14.9821L14.9821 6.11918C14.7008 6.11543 14.4355 6.04482 14.2015 5.9225L14.3633 6.10131L13.5451 6.2987C12.3564 6.58549 11.2985 7.21972 10.5198 8.13017L22.1934 20.9393Z' fill='white' stroke='%23616161'/%3E%3C/svg%3E%0A") !important;
      }
    }
    .switches .switch label {
      top: .5rem
    }
  }

  .switches {
    list-style: none;
    position: relative;
    padding: 0 !important;
    margin: 0 -2rem;
    background-color: $hue-grey-lighter;

    .switch-container, .results-container {
      max-width: 23rem;
      margin-left: 2rem;
    }

    li {
      border-color: $hue-grey-medium !important;

      .label {
        position: relative;
        p {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          white-space: nowrap;
        }
      }
    }
  }
}
