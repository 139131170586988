@use "../../../assets/styles/shared" as *;

.notes {

  .note-input {
    resize: none;
    line-height: 1.25rem !important;
  }

  .dimmed {
    opacity: .5;
  }
}
