@use "../../../assets/styles/shared" as *;

.viewer-saved {
  .save-btn {

    .btn {
      background-color: $hue-blue !important;
      border-color: $hue-blue !important;
      color: $hue-white !important;
      font-size: 1rem !important;
      padding: .5rem;
      .bi {
        font-size: 1.1rem;
        &:before {
          vertical-align: -4px;
        }
      }
    }
  }

  .add-tag-add-remove-edit-link {
    text-align: center !important;
  }

  .tags-container {
    flex-wrap: wrap;
  }
}
