@use "../../assets/styles/shared" as *;

.text-link {

  padding: 1px !important;
  border-radius: 0 !important;
  color: $hue-black-text !important;
  margin: 0 -2px;

  &:hover, &:active, &:visited {
    border-color: transparent !important;
  }

  &:focus, &:focus-within, &:focus-visible {
    border-color: $hue-blue !important;
    box-shadow: 0 0 0 4px rgba(49, 132, 253, .5) !important;
  }

  div {
    margin: 0 !important;
    text-decoration-color: $hue-blue !important;
    text-decoration-thickness: 2px !important;
    text-underline-offset: 5px !important;

  }

  .btn-underline {
    height: 2px;
    margin: -3px 0 0 0 !important;
  }

  i {
    font-size: .9rem !important;
  }
}
