@use "../../../../assets/styles/shared" as *;

.search-industry-dropdown {
  width: 13rem;
  height: 6rem;

  .dropdown-menu {
    width: 13rem;
  }

  .icon {
    width: 1.5rem;
  }

  .dropdown-toggle::after {
    border-top: 0.5rem solid;
    border-right: 0.5rem solid transparent;
    border-left: 0.5rem solid transparent;
  }

  @include media-breakpoint-down(sm) {
    width: 4rem;

    .dropdown-menu {
      width: 100%;
      text-align: center ;
      padding-bottom: 0;
      a {
        font-size: 1.1rem;
        padding: .5rem 0 !important;
        border-bottom: 1px solid $hue-grey-medium;
        &:last-child {
          border-bottom: 0;
        }
      }

      img {
        display: none;
      }
    }

    .icon {
      width: 1rem;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }

  @include media-breakpoint-only(sm) {

  }

  @include media-breakpoint-only(md) {

  }

  @include media-breakpoint-only(lg) {

  }

  @include media-breakpoint-only(xl) {

  }

  @include media-breakpoint-only(xxl) {

  }
}
