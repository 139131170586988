@use "../../assets/styles/shared" as *;

.toggle-notify {

  position: relative;

  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
    position: absolute;
    left: 3rem;
    top: .75rem;
  }

  &.notifyOn {
    label {
      left: .75rem;
      color: $hue-white !important;
    }
  }

  .form-switch .form-check-input {
    width: 5rem;
    height: 2.25rem;
    background-color: $hue-white;
    color: $hue-brown;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAzMSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41NTY4ODUiIHk9IjAuMDY3MDE2NiIgd2lkdGg9IjMwIiBoZWlnaHQ9IjI0IiByeD0iMTIiIGZpbGw9IiM0MDQxNDIiLz4KPHBhdGggZD0iTTE1LjU1NzUgMjAuMzQxOUMxNi42NzYyIDIwLjM0MTkgMTcuNTk5NyAxOS41NzQgMTcuNzY4NSAxOC41NzFIMTMuMzQ2NEMxMy41MTUyIDE5LjU3NCAxNC40Mzg3IDIwLjM0MTkgMTUuNTU3NSAyMC4zNDE5WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTIxLjQ0MzYgMTcuOTA5QzIxLjExMjYgMTcuMjgwMSAxOS45NjQxIDE0Ljk0NjYgMTkuOTY0MSAxMi42MjNDMTkuOTY0MSAxMi4xNjMgMjAuMDAzOCAxMS42NjY1IDIwLjA4MzIgMTEuMTU2N0MyMC4yNjg2IDkuOTcxNzggMTkuOTM0MyA4LjgwNjY3IDE5LjEzNjYgNy44NzY1OEMxOC41MTEgNy4xNDUwOCAxNy42NjM3IDYuNjQxOTggMTYuNzE3IDYuNDEwMjhDMTYuOTcxOSA2LjEzMjI0IDE3LjEyNzQgNS43NjQ4NCAxNy4xMjc0IDUuMzYxMDRDMTcuMTI3NCA0LjQ5NzEzIDE2LjQyNTcgMy43OTIxMSAxNS41NTg1IDMuNzkyMTFDMTQuNjk0NiAzLjc5MjExIDEzLjk4OTYgNC40OTcxMyAxMy45ODk2IDUuMzYxMDRDMTMuOTg5NiA1Ljc2NDg0IDE0LjE0ODUgNi4xMzIyNCAxNC40MDAxIDYuNDEwMjhDMTMuNDUzNCA2LjYzODY3IDEyLjYwNjEgNy4xNDUwOCAxMS45ODA1IDcuODc2NThDMTEuMTgyOCA4LjgwOTk5IDEwLjg0NTIgOS45NzUwOSAxMS4wMzM5IDExLjE1NjdDMTEuMTEzMyAxMS42Njk4IDExLjE1NjMgMTIuMTYzIDExLjE1NjMgMTIuNjIzQzExLjE1NjMgMTQuOTUzMiAxMC4wMDc4IDE3LjI4MDEgOS42NzAxNyAxNy45MDlIMjEuNDQzNlpNMTUuNTU4NSA0LjQ1NDFDMTYuMDU4MyA0LjQ1NDEgMTYuNDY1NSA0Ljg2MTI0IDE2LjQ2NTUgNS4zNjEwNEMxNi40NjU1IDUuODU3NTMgMTYuMDY1IDYuMjYxMzMgMTUuNTY4NSA2LjI2Nzk2SDE1LjU0ODZDMTUuMDUyMSA2LjI2MTMzIDE0LjY1MTYgNS44NTc1MyAxNC42NTE2IDUuMzYxMDRDMTQuNjUxNiA0Ljg2MTI0IDE1LjA1ODcgNC40NTQxIDE1LjU1ODUgNC40NTQxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==') !important;

    &:checked {
      background-color: $hue-blue;
      background-image: url("data:image/svg+xml,%3Csvg width='30' height='27' viewBox='0 0 30 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.254578' width='30' height='26.4909' rx='13.2454' fill='white'/%3E%3Cpath d='M15.0022 24.7454C16.5226 24.7454 17.7776 23.7019 18.007 22.3389H11.9974C12.2268 23.7019 13.4818 24.7454 15.0022 24.7454Z' fill='%230D6EFD'/%3E%3Cpath d='M23 21.4393C22.5502 20.5846 20.9893 17.4134 20.9893 14.2557C20.9893 13.6305 21.0433 12.9557 21.1513 12.263C21.4032 10.6527 20.9488 9.06932 19.8648 7.80533C19.0146 6.81123 17.8631 6.12752 16.5766 5.81265C16.923 5.43479 17.1344 4.9355 17.1344 4.38673C17.1344 3.21269 16.1808 2.25458 15.0022 2.25458C13.8282 2.25458 12.8701 3.21269 12.8701 4.38673C12.8701 4.9355 13.086 5.43479 13.4279 5.81265C12.1414 6.12302 10.9899 6.81123 10.1397 7.80533C9.05567 9.07382 8.59685 10.6572 8.85324 12.263C8.9612 12.9602 9.01967 13.6305 9.01967 14.2557C9.01967 17.4224 7.45882 20.5846 7 21.4393H23ZM15.0022 3.15421C15.6815 3.15421 16.2347 3.70751 16.2347 4.38673C16.2347 5.06146 15.6905 5.61022 15.0157 5.61923H14.9888C14.314 5.61022 13.7697 5.06146 13.7697 4.38673C13.7697 3.70751 14.323 3.15421 15.0022 3.15421Z' fill='%230D6EFD'/%3E%3C/svg%3E%0A") !important;
    }
  }

  @include media-breakpoint-down(sm) {
    transform: scale(.8);
    margin-right: -.7rem;
  }
}
