@use "../../../assets/styles/shared" as *;

.ask-us-email {
  max-width: 65rem;
  margin: 0 auto;
  //max-width: 600px;

  input, textarea, .text-end, .recaptcha {
    max-width: 30rem;
  }

  .recaptcha {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .recaptcha-error {
    display: none;
    margin-bottom: .5rem;
    margin-top: -.5rem;
    &.invalid {
      display: block;
    }
  }
}
