@use "../../assets/styles/shared" as *;

.login-registration {

  .form-check {
    .form-check-input {
      height: 1.5rem;
      width: 1.5rem;

    }

    label {
      margin-left: .75rem;
      margin-top: .25rem;
    }
  }

  .hr {
    margin: 1rem 0 2rem;
    height: 1px;
    width: 100%;
    background-color: $hue-grey;

  }

  /* small tablet and up */
  @include media-breakpoint-up(md) {
    .hr {
      margin: 0;
      height: 100%;
      width: 1px;
    }
  }

}
