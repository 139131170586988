@use "../../../assets/styles/shared" as *;

.filters-cascade {
  .accordion-header {
    width: 70%;
  }

  .accordion-button::after {
    margin-top: -5px;
  }

  .loading {
    .accordion-button::after {
      display: none;
    }

    .lds-spinner {
      position: absolute;
      left: 14rem;
    }
  }
}
