@use "../../../../../assets/styles/shared" as *;

.news-feed-block {
  max-width: 65rem;
  margin: 0 auto;
  padding-top: 1rem;

  h2 {
    margin-top: 0;
  }
}
