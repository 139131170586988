@use "../../assets/styles/shared" as *;

.content-view {



  ul {
    padding-left: 0;
  }


  p {
    @include font-family(light);
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  a {
    padding-top: .4rem !important;
    padding-bottom: .4rem !important;

    &.active {
      @include font-family(bold);
    }
  }

  .no-header {
    margin-top: 4rem;
  }

  .page-container {
    .content {
      border-radius: .5rem;
    }
  }


  @include media-breakpoint-down(lg) {
    .subheader-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
