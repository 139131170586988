@use "../../../assets/styles/shared" as *;

.bibliography-card {
  .title {
    padding-top: 2px;
  }

  sel {
    padding: 1px 2px;
    background-color: $hue-highlight;
  }

  ul {
    list-style: none;
  }

  .link-list {
    padding-left: 0 !important;
  }

  .tab-pane {
    display: none;
  }
  .active {
    display: block;
  }

  .fade:not(.show) {
    opacity: 0;
  }

  .fade {
    transition: opacity .15s linear;
  }

  .btn-link {
    cursor: pointer !important;
    text-decoration: none !important;
    width: fit-content;
    &:hover {
      text-decoration: none !important;
    }
  }

  .publish-link {
    width: fit-content;
  }

  label {
    a {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical
    }
  }

  .doc-content {
    .col-lg-2, .col-lg-10 {
      margin: 5px 0;
    }
  }

  img {
    max-height: 15rem;
  }

  .excerpt {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical
  }

  .expand {
    .btn:focus {
      box-shadow: none;
    }
  }

  .nav-tabs {
    border: 0;
  }

  .nav-item {
    a {
      border: 0;

      .tab-indicator {
        margin-left: -1.5rem;

        .backdrop {
          height: 5px;
          width: calc(100% + 1.5rem);
          background-color: $hue-grey-medium;
        }
      }

      &.active {
        .backdrop {
          background-color: $hue-blue-underline;
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    .nav-tabs {
      display: flex;
      justify-content: space-between;
    }
    .nav-item {

      a {
        .tab-indicator {
          .backdrop {
            background-color: transparent;
            width: 100%;
          }
        }

        &.active {
          .tab-indicator {
            .backdrop {
              background-color: $hue-blue-underline;
            }
          }
        }
      }


    }

    .nav-item a .tab-indicator {
      margin-left: 0;
      margin-top: 1px !important;
    }
  }
}
