@use "../../../../../assets/styles/shared" as *;

.collapse-block{
  .accordion-button {
    padding: .5rem 1rem;
  }

  .accordion-header {
    margin: .5rem !important;
  }
}
