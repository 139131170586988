@use "../../assets/styles/shared" as *;

.link-btn {
  padding: .75rem .5rem .5rem;
  border-radius: 0 !important;
  color: $hue-black-text !important;
  width: fit-content !important;

  .long-arrow-right, .long-arrow-left {
    display: inline-block;

    position: relative;
    top: 1px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-top: 2px solid $hue-black;
    border-left: 2px solid $hue-black;
  }

  .arrow-right,
  .long-arrow-right{
    transform: rotate(135deg);
    margin-left: .75rem;
  }

  .arrow-left,
  .long-arrow-left{
    transform: rotate(315deg);
    margin-right: .75rem;
  }


  .long-arrow-right::after, .long-arrow-left::after{
    content: "";
    display: block;
    width: 2px;
    height: 16px;
    background-color: $hue-black;
    transform: rotate(-45deg) translate(5px, 2px);
  }

  &:hover, &:active, &:visited {
    border-color: transparent !important;
  }

  &:focus, &:focus-within, &:focus-visible {
    border-color: $hue-blue !important;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, .5) !important;
  }
}
