@use "../../../../assets/styles/shared" as *;

.collection-toolbar {
  top: 0;
  right: 1.5rem;

  .bi-bell {
    color: $hue-black !important;
  }

  .create-alert {
    span {
      color: $hue-black !important;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: .6rem;
  }
}
