@use "../../assets/styles/shared" as *;

.industry-menu {
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    display: block !important;

    .nav-item {
      border-radius: 0 !important;
      margin: 1rem 0 0;
      display: block;
      a {
        width: 100%;
        height: calc(.65*12rem);
        padding-top: 1.25rem;
        border-radius: 0 !important;
        color: $hue-blue;
      }

      .icon {
        width: 4rem;
        margin: 0 0 .5rem;
      }

      h4 {
        font-size: 1.1rem;
        margin: 0 0 1rem;
      }
    }
  }


  @include media-breakpoint-only(md) {
    max-width: 760px;
    margin-left: 10.5vw;
    .nav-item {
      a {
        padding-top: 1.75rem;
        border-radius: .75rem !important;
        color: $hue-blue;
      }
      .icon {
        margin: 0 0 .75rem;
        width: 4rem;
      }
      h4 {
        font-size: 1.2rem;

      }
    }

    .nav-item {
      margin: 1rem .75rem 1.5rem;
      a {
        width: 22vw;
        height: calc(.8*12rem);
      }
    }
  }

  @include media-breakpoint-only(lg) {
    max-width: 990px;
    margin-left: 14vw;

    .nav-item {
      a {
        padding-top: 1.75rem;
        border-radius: .75rem !important;
      }

      .icon {
        margin: .25rem 0 .75rem;
        width: 5rem;
      }

      h4 {
        font-size: 1.3rem;

      }
    }

    .nav-item {
      margin: 1rem 1rem 1.5rem;
      a {
        width: calc(.89*22vw);
        height: calc(.89*12rem);
      }
    }
  }

  @include media-breakpoint-only(xl) {
    max-width: 1180px;
    margin-left: 12vw;

    .nav-item {
      margin: 1rem 1rem 2rem;

      h4 {
        margin-top: 1rem;
        font-size: 1.8rem;
      }

      .icon {
        margin: 1rem 0 .5rem;
        width: 6rem;
      }

      a {
        width: calc(1.07*20vw);
        height: calc(1.07*12rem);
      }
    }
  }

  @include media-breakpoint-only(xxl) {
    max-width: 1430px;

    .nav-item {
      margin: 1rem 1rem 2rem;
      width: 22%;

      a {
        padding-top: 2.25rem;
        border-radius: .75rem;
        width: 100%;
        height: 100%;
      }

      .icon {
        margin: 0 0 1.25rem;
        width: 7.5rem;
      }

      h4 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}
