@use "../../../../assets/styles/shared" as *;

.document-search {

  .search-btn {
    &:focus {
      box-shadow: none;
    }
  }

  .bi-search {
    font-size: 1.1rem;
  }
  input {
    width: 21rem;
  }

  .search-panel {
    z-index: 1200;
    top: 3.55rem;
  }

  .highlight-all {
    input {
      width: 1.5rem;
      height: 1.5rem;
    }

    label {
      padding-top: 0.25rem;
      margin-left: .5rem;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-right: 0 !important;
    background-color: $hue-white;

    .toggle-btn {
      padding: .5rem 0;
    }

    .search-panel {
      display: flex;
      position: relative !important;
      top: unset !important;
    }
  }

}
