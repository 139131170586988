@use "../../../../../assets/styles/shared" as *;

.posts {
  .fade-out {
    height: 8rem;
    z-index: 100;
    position: relative;
    width: 100%;
    margin-top: -8rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .post {
    list-style: none;
    max-height: 20rem;
    overflow: hidden;
  }

  .post-layout {
    max-width: $contentMaxWidth;
    margin: 0 auto;
  }

  hr {
    margin: .5rem auto 2rem;
    max-width: $contentMaxWidth;
  }

  .post-wrapper {
    &:last-child {
      hr {
        display: none;
      }
    }
  }
}
