@use "../../../../assets/styles/shared" as *;

.search-paging {
  input {
    &.float-top {
      position: relative;
      z-index: 100;
    }

    width: 3.5rem;
    font-size: .8rem;
    line-height: 24px;
  }

  a {
    color: $hue-black !important;
  }

  .page-link {
    background-color: transparent !important;
    border: 0 ;
    &:hover {
      background-color: transparent !important;
    }
  }


  @include media-breakpoint-down(lg) {
    input {
      width: 2rem;
      font-size: .8rem;
      line-height: 24px;
    }
  }
}
