@use "../../../../assets/styles/shared" as *;

.tags-apply {

  .tag-scroll-container {
    max-height: 21rem;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -1rem;
    padding-right: 1rem;
  }

  hr {
    margin: .5rem -1rem;
  }
}
