@use "../../assets/styles/shared" as *;

.viewer-view {
  width: 100% !important;

  .page-container {
    &.fullscreen {
      overflow-x: hidden;
      max-width: unset !important;
      margin: 0 !important;
      padding: 0 !important;
      .pdf-toolbar, .viewer-pdf {
        max-width: unset !important;
        margin: 0 !important;
        padding: 0 !important;
      }

    }
  }

  .industry-nav {
    border-radius: 1rem;
  }

  .tools-panel-col {
    height: $sz-tools-panel-height;
  }

  .viewer-panel-toggle {
    position: relative;
    z-index: $zindex-dropdown;
  }

  h1 {
    font-size: 1.75rem;
    @include font-family(normal)
  }

  .viewer-media {
    height: $sz-tools-panel-height;
  }

  .subheader {
    border-radius: 1rem;
    min-height: 7rem;
  }

  @include media-breakpoint-down(lg) {
    .page-container {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .inner-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .back-search {
      padding-left: .25rem !important;
      padding-right: .25rem !important;
    }

  }
}
