@use "../../../assets/styles/shared" as *;

.search-input {

  .sel-container {
    top: .6rem;
    left: 6.5rem;
    span {
      padding: 4px 0;
      background-color: lightblue;
    }
  }

  input {
    @include font-family(normal);
    color: $hue-black-text;
  }

  input, input:focus {
    background-color: transparent;
  }

  #button-filters {
    height: 100%; /* todo: investigate why */
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .adv-search-title {
    border-left: 1px solid $hue-grey-medium;
    padding-top: .75rem;
    padding-left: .6rem !important;
    flex: 1 1 auto;

    .header-underline {
      height: 4px;
      width: 6.8rem;
      background-color: $hue-blue-medium2;
    }
  }

  i:before {
    vertical-align: -.25rem !important;
  }

  .dropdown-menu {
    i:before {
      vertical-align: -.2rem !important;
    }
  }

  .vertical-hr {
    margin: .5rem .5rem .5rem 4px !important;
    height: 1.7rem !important;
    width: 1px;

    background-color: $hue-grey-medium;
  }


  @include media-breakpoint-down(lg) {

    .sel-container {
      top: .6rem;
      left: .7rem;
      span {
        padding: 4px 0;
        background-color: lightblue;
      }
    }


  }
}
