@use "../../../../assets/styles/shared" as *;

.zoom {
  input[type="range"]::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    z-index: 2;
    top: -3px;
    height: 1.5rem !important;
    width: 1.5rem !important;

    &:active {
      background-color: #3889ff !important;
    }
  }

  .form-range {
    min-width: 130px;
  }

  .range-indicator {
    top: 1.25rem;
    position: absolute;
    border-radius: .25rem;
    height: .5rem;
    background-color: $hue-grey-dark;
    width: 1rem;
  }
}
