@use "../../../assets/styles/shared" as *;

.cite-modal {
  .modal-dialog {
    max-width: 30rem  !important;
    width: 80% !important;
  }

  left: 50% !important;
  transform: translate(-50%, 0);

  .modal-body .btn {

    background-color: $hue-white !important;
    border: 1px solid $hue-grey !important;;
  }
}

