@use "../../../assets/styles/shared" as *;

.search {
  #search-toggle-btn.btn-link {
    min-width: 8rem;

    &:hover {
      background-color: transparent;
      text-decoration: underline !important;
    }

    &:focus, &:active {
      box-shadow: none;
      text-decoration: underline !important;
    }
  }

  .num-selected {
    margin-left: -.4rem;
  }

  .col-adv-toggle {
    flex: 0 0 auto !important;
    width: 11.5% !important;
  }

  .search-info-link {
    .bi-info-circle-fill {
      color: $hue-blue-underline;
    }

    a {
      color: black;
    }
  }

  @include media-breakpoint-down(lg) {
    .search-container {
      background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    }
  }

}
