@use "../../../../../assets/styles/shared" as *;

.rich-text {
  h1 {
    line-height: 2.5rem;
  }

  div {
    margin-bottom: 1rem;
  }

  .br {
    margin: 0;
    padding: .5rem 0;
  }

  .lf {
    margin-bottom: 0 !important;
    display: block !important;
  }

  i {
    font-style: italic !important;
  }

  .link-btn {
    padding: 2px 8px 4px;

    div {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    h1 {
      font-size: 1.8rem;
      margin-bottom: .5rem !important;
    }
  }

}
