@use "../../../assets/styles/shared" as *;

/* bootstrap adds dynamically so can't be scoped with usual class scope */
.slideout {
  width: 24rem !important;

  .filters {
    top: 7rem;
    bottom: 0;
    left: 0;
  }

  .btn-close {
    background-size: .8rem !important;
  }

  .accordion-button {
    font-size: .9rem;
    padding: 0 1.1rem !important;
    background-color: $hue-white !important;
    box-shadow: none !important;
    span {
      font-size: .85rem !important;
    }
  }

  input {
    margin-top: 2px;
    cursor: pointer;
  }
}

.filters-slideout {
  @include media-breakpoint-down(lg) {
    button {
      background-color: $hue-white !important;
      border: 1px solid $hue-grey-medium;
    }
  }
}


