@use "../../../../../assets/styles/shared" as *;

.layout{
  margin: 0 auto;
  font-size: 1.15rem;

  h1 {
    margin: .5rem 0 .5rem;
    font-size: 2.5rem;
    @include font-family(bold)
  }

  .subtitle {
    margin: .5rem 0 .25rem;
    font-size: 1.75rem;
  }

  h2 {
    margin: 1rem 0 .25rem;
    font-size: 2rem;
  }

  h3 {
    margin: .5rem 0 .25rem;
    font-size: 1.5rem;
  }

  h4 {
    margin: .5rem 0 .25rem;
    font-size: 1.2rem;
  }

  h6 {
    margin: .5rem 0 .25rem;
    font-size: 1.1rem;
  }
}
