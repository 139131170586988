@use "../../../../../assets/styles/shared" as *;

.content{
  max-width: $contentMaxWidth;
  margin: 0 auto;

  img {
    max-width: 100% !important;
    height: auto !important;
  }
}
