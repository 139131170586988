@use "../../../assets/styles/shared" as *;

.view-subheading {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: $hue-brown;

  h5, h2 {
    margin-bottom: 0 !important;
    color: $hue-white !important;
  }

  h5 {
    font-size: 1rem !important;
    line-height: 1.4rem;
  }

  h2 {
    font-size: 1.1rem !important;
    margin-bottom: .25rem !important;
  }

  .inner {
    display: flex;
    justify-content: space-between;
  }

  .create-alert {
    margin-top: .5rem;
    i, span {
      color: $hue-white !important;
    }
  }

  .btn-icon-only {
    &:focus, &:active, &:hover {
      i {
        color: $hue-white !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .info-btn {
      position: absolute;
      top: 3.5rem;
      right: 5.2rem;
    }

    .toggle-notify {
      padding-bottom: 1.5rem;
    }
  }

  @include media-breakpoint-down(lg) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .settings-btn {
      margin-right: 3.5rem ;
    }
  }
}

