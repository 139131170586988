@use "../../../assets/styles/shared" as *;

footer {
  background-color: $hue-navy-blue-100;
  padding: 4rem 0 6rem;

  .hr {
    background-color: $hue-grey-darkest;
    height: 1px;
  }

  .copyright {
    color: $hue-blue-2;
  }

  .version {
    margin-top: -.25rem;
  }

  .social-icons {
    .btn {
      border-radius: 5px !important;
      flex: none !important;
    }

    &.btn-group {
      margin-left: -.5rem;
      width: calc(100% + 1rem);
      justify-content: space-between;
    }
  }

  .nav a {
    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      padding-right: 0 !important;
    }
  }


  /* mobile */
  @include media-breakpoint-only(xs) {
    padding-top: 3rem;
    padding-bottom: 0;

    .container {
      padding: 0;
    }

    .logo {
      margin-bottom: 2rem;
    }

    .top {
      margin: 0 1.6rem 0rem;
    }

    .bottom {
      flex-direction: column;
      padding-bottom: 3rem !important;

      .copyright {
        margin-left: .5rem;
      }

      .nav {
        margin-left: 0;
        flex-direction: column;

        a {
          font-size: 1rem !important;
          &:first-child {
            padding-left: .5rem !important;
          }
        }
      }
    }
  }

}
