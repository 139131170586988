@use "../../../../assets/styles/shared" as *;

.accessibility {
  /*margin-top: 3px;
  padding: 5px !important;*/
  i::before {
    font-size: 1.4rem !important;
  }

  .show-accessibility {
    z-index: 2001;
    right: 0;

    .link-btn {
      padding-right: .5rem;
      padding-left: .5rem;
    }

    .close-btn {
      z-index: 2002;
      right: .5rem;
      background-color: transparent !important;
      i {
        color: $hue-grey-dark !important;
      }
    }
  }
}
