@use "../../../assets/styles/shared" as *;

.header-idl
{
  position: relative;
  z-index: 1;
  margin-bottom: 2px; /* compensate for shadow */

  .toggle-active {
    display: none !important;
  }

  border-bottom: 1px solid $hue-grey-darker !important;
  box-shadow: -1px 0px 9px 2px rgba(169, 169, 169, 0.5);
  -webkit-box-shadow: -1px 0px 9px 2px rgba(169, 169, 169, 0.5);
  -moz-box-shadow: -1px 0px 9px 2px rgba(169, 169, 169, 0.5);

  .active_select {
    background-color: $nav-underline;
    height: 6px;
    margin-top: 1.2rem;
  }

  .navbar {
    --bs-navbar-color: rgb(0, 0, 0, 1);
    --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  }

  .navbar-toggler {
    &:focus-visible, &:focus {
      outline: var(--bs-link-hover-color) auto 1px;
      box-shadow: none;
    }
  }

  /* mobile */
  h1 {
    font-size: 1.1rem;
  }
  .navbar > .container {
    padding: 0 1.5rem;
  }

  .library-dropdown-link {
    margin: .75rem 0 0 0;
  }


  /* small tablet and up */
  @include media-breakpoint-up(lg) {
    h1 {
      margin-top: -2px !important;
    }

    .link {
      padding: .25rem .5rem;
      margin: .2rem .5rem;
    }

    .library-dropdown-link {
      margin: .5rem 0 0 .5rem;
    }

    h1 {
      font-size: 1.6rem;
    }

    .navbar > .container {
      padding: 0;
    }
  }


  @include media-breakpoint-down(lg) {
    .library-menu-open-select {
      position: absolute;
      top: 1.2rem;
      width: 5.5rem;
    }
  }
}
