@use "../../../../../assets/styles/shared" as *;

a.link {
  text-decoration-color: $hue-blue !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 5px !important;
}

.link {

}

