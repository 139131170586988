@use "../../../../assets/styles/shared" as *;

.viewer-mode {
  .dropdown-menu {
    min-width: 5rem;
  }
  span {
    white-space: nowrap;
  }

  button {
    padding-left: 2px;
    padding-top: .5rem;
  }
}
