@use "../../../../../assets/styles/shared" as *;

.card-grid {

  .cards {

    .card {
      //min-height: 27.5rem;
      height: 100%;
      line-height: 1.5rem;
    }
    margin: 2rem 0;

    .grey-background {
      background-color: $hue-grey-light;
    }

    .content-card {
      border-radius: 0;

      .img-container {
        width: fit-content;
      }

      img {
        height: auto !important;
        max-width: 100%;
      }

      .description {
        max-height: 12rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      .has-link {
        cursor: pointer;
      }

      .link-container {
        left: .25rem;
        bottom: 1rem;
      }

      .link {
        font-size: .95rem;
        text-decoration: none;

        .long-arrow-right{
          display: inline-block;
          margin-left: .8rem;
          position: relative;
          top: 1px;
          width: 12px;
          height: 12px;

          border-top: 2px solid $hue-blue;
          border-left: 2px solid $hue-blue;
        }

        .arrow-right,
        .long-arrow-right{
          transform: rotate(135deg);
        }

        .long-arrow-right::after{
          content: "";
          display: block;
          width: 2px;
          height: 16px;
          background-color: $hue-blue;
          transform: rotate(-45deg) translate(5px, 2px);
        }
      }

      a {
        text-decoration: none !important;
        color: $hue-black;
      }
      margin: 0 .5rem;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

