@use "../../../../assets/styles/shared" as *;

.rotation-button {
  @include media-breakpoint-down(lg) {
    .bi-arrow-clockwise {
      font-size: 1.4rem;
    }

  }
}
