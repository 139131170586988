@use "../../../assets/styles/shared" as *;

.breadcrumb-custom {
  i {
    -webkit-text-stroke: 1px $hue-black-text;
  }

  .breadcrumb {
    width: max-content;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .breadcrumb-item {
    &:not(:first-child):not(:last-child) {
      .label {
        padding-top: .25rem;
        border-bottom: 1px solid $hue-black;
        line-height: 1rem;
      }
    }

    a {
      cursor: pointer;
      color: $hue-black;
      text-decoration: underline;
    }
  }

  .breadcrumb-item+.breadcrumb-item::before {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-right: 0.75rem;
    margin-left: -3px;
    margin-top: 6px;
    content: var(--bs-breadcrumb-divider, "");
  }

  /* mobile */
  .container {
    padding: 0 1.5rem;
  }

  /* small tablet and up */
  @include media-breakpoint-up(sm) {
    .container {
      padding: 0 3rem;
    }
  }

  @include media-breakpoint-down(lg) {
    font-size: .875rem !important;
    .breadcrumb {
      display: flex;
      width: 100%;
    }

    .bi-house-door {
      margin-right: -.75rem !important;
    }

    .breadcrumb-item {
      padding-top: 1px;
      .label {
        min-width: 1rem;
        margin-left: 3px;
      }

      // home icon
      &:first-child {
        .label {
          margin: 0 !important;
        }
      }
    }

    .breadcrumb-item::before {
      margin-right: .3rem !important;
    }
  }
}
