@use "../../../../../assets/styles/shared" as *;

.viewer-search-list {
  .paging {
    display: block !important;
  }
  .page-item {
    width: 100%;
    margin-bottom: .5rem;
    text-align: center;
  }
}
