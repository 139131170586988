@use "../../../assets/styles/shared" as *;

.download-modal {
  width: 25rem !important;
  height: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  &.minimized {
    top: auto !important;
    left: auto !important;
    bottom: -1.5rem !important;
    right: -13rem !important;
  }

  .modal-dialog {
    margin: 0 !important;
  }
}

