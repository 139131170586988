@use "../../../assets/styles/shared" as *;

.viewer-metadata {
  border-bottom-right-radius: 1rem;
  height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;

  .entry {
    margin-top: .5rem;
    margin-bottom: .6rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: .95rem;
    background-color: $hue-white;
    margin-top: 1rem;
    height: auto;
  }
}
