@use "../../../../../assets/styles/shared" as *;

.content-grid {
  padding: 0 0 !important;

  h2, h3, h4, h5, h6 {
    text-align: left;
  }

  .row {
    margin-right: 0;
    margin-left: 0;

    &.column-headers {
      background-color: $hue-grey-lighter;
      .col {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
      }
    }

    &:last-child {
      //border-bottom: 1px solid $hue-grey-dark;
    }

    //border-top: 1px solid $hue-grey-dark;
    .col {
      &:first-child {
        //border-left: 1px solid $hue-grey-dark;
      }
      //border-right: 1px solid $hue-grey-dark;
    }
  }
}
