@use "../../../../assets/styles/shared" as *;

.constraint-dropdown {
  .constraint-toggle:after {
    float: right;
    margin-top: 0.5rem;
  }

  .search-field {
    input {
      width: calc(100% - 1.5rem);
    }
  }

  .search-select {
    color: $hue-blue;
  }

  .scroll-container {
    max-height: 20.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .constraint-menu {
    min-width: unset;
  }

  @include media-breakpoint-down(lg) {
    .constraint-menu {
      width: 100%;
    }
  }
}
