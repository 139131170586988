@use "../../../assets/styles/shared" as *;

.login-modal {
  .modal-dialog {

    @include media-breakpoint-up(md) {
      width: 85%;
      max-width: 1200px;
    }

    @include media-breakpoint-only(sm) {
      margin: 1rem;
      width: calc(100% - 2rem);
    }

    @include media-breakpoint-down(xs) {
      margin: .5rem 0;
      width: calc(100% - 1rem);
    }

    left: 50%  !important;
    transform: translate(-50%, 0) !important;
  }
}



