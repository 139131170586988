@use "../../assets/styles/shared" as *;
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

.report-issue {
  textarea.form-control {
    height: 8rem;
    resize: none;
  }

  #dropdown-issue-toggle:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
  }

  .dropdown-menu {
    z-index: 2000;
  }

  .dropdown-toggle {
    background-color: transparent !important;
  }

  #dropdown-issue-toggle input {
    padding-top: .4rem;
    padding-bottom: .4rem;
  }

  .not-initial-render {
    .dropdown-toggle {
      &.show {
        &::after {
          animation-name: rotateDown;
          animation-duration: .3s;
        }
      }
    }
  }

  .dropdown-toggle {
    &::after {
      float: right;
      margin-right: 1rem;
      margin-top: -1.75rem;
      height: 20px;
      width: 20px;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2300000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      border: 0 !important;
      transform: rotate(0deg);
    }

    &.show {
      &::after {
        animation-name: rotateUp;
        animation-duration: .3s;
        transform: rotate(-180deg);
      }
    }
  }

  @include media-breakpoint-down(md) {
    .lastname, .firstname {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .issue-dropdown-col {
      padding-right: 0 !important;
    }
  }
}



