@use "../../../../assets/styles/shared" as *;

.date-picker {


  .calendar-container {
    z-index: 1000;
    left: -7.25rem;
  }

  .react-calendar {
    border-radius: .3rem;
    border-color: $hue-grey-medium;
    border-top: 0;
  }

  .clear-btn {
    right: 1rem;
  }
}
