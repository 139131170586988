@use "../../assets/styles/shared" as *;

.home {
  .view-scroll-container {
    top: 11.8rem !important;
  }
  .search-input {
    border-radius: .5rem;
  }

  h1 {
    @include font-family(light);
  }

  h2 {
    @include font-family(normal);
  }

  input {
    padding: 1.5rem !important;
    font-size: 1.5rem !important;
    @include font-family(light);
  }

  .clear-search-btn {
    .text-small {
      font-size: 1.1rem !important;
    }
    button {
      padding-left: .5rem !important;
    }

    i {
      font-size: 2rem  !important;
      &:before {
        font-size: 2rem  !important;
        vertical-align: -.55rem !important;
      }
    }
  }

  .search-btn {
    background-color: white;
    button {

      width: 5rem;
      padding: 0 1.3rem;
    }

    i {
      font-size: 2.3rem;
      color: $hue-blue;
      &:before {
        vertical-align: -.5rem !important;
      }
    }
  }

  .vertical-hr {
    display: none;
  }

  .input-container {
    h2 {
      font-size: 3rem;
    }
  }

  .dropdown-toggle {
    /* remove bs arrow in favor of custom */
    &:after {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .search-industry-dropdown {
      width: 3rem;
      border-right: 1px solid $hue-grey-medium;
      height: auto;
    }

    .input-container {
      margin: 1rem 1rem !important;
      width: calc(100% - 2rem) !important;
      h2 {
        font-size: 1.5rem;
      }
    }

    .search-input-field  {
      font-size: 1rem !important;
      line-height: 1.2rem !important;
      padding: 1rem .5rem !important;
    }

    .clear-search-btn button {
      padding: 0;
    }

    .search-btn {
      button {
        width: 2rem;
        padding: .5rem 1.5rem;
      }

      i {
        font-size: 1.5rem;
        margin-left: -1rem;
        &:before {
          vertical-align: -.5rem !important;
        }
      }
    }

    .container {
      padding: 0 !important;
    }
  }

  @include media-breakpoint-down(lg) {
    .arrow-down, .arrow-up {
      display: block;
    }

    .view-scroll-container {
      top: 10.5rem;
    }
  }

  @include media-breakpoint-only(md) {
    .input-container {
      width: 90% !important;

      h2 {
        font-size: 2.5rem;
      }
    }
  }
}
