@use "../../../assets/styles/shared" as *;

.header-ucsf {
  position: relative;
  z-index: 2;

  nav {
    min-height: 3rem;
    background-color: $hue-navy-blue-100;

    .navbar-dark {
      --bs-nav-link-color: rgba(255, 255, 255, 1);
      --bs-nav-link-hover-color: rgba(255, 255, 255, 0.55);
      --bs-nav-link-disabled-color: rgba(255, 255, 255, 0.55);
    }
  }

  /* mobile */
  .navbar > .container {
    padding: 0 1.5rem;

  }

  @include media-breakpoint-down(sm) {
    .navbar a {
      font-size: .6rem;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  /* small tablet and up */
  @include media-breakpoint-up(sm) {
    .navbar > .container {
      padding: 0;
    }
  }


}
