@use "../../assets/styles/shared" as *;

.my-library {
  .search-menu {
    margin-bottom: 1rem;
  }

  #button-filters {
    border: 1px solid $hue-grey-medium;
  }

  .content-container {
    border-radius: 1rem;
    border: 1px solid $hue-grey-lighter;
  }

  .view-scroll-container {
    overflow-x: hidden;
  }

  @include media-breakpoint-down(lg) {
    .content-container {
      padding: 0 !important;
      border-radius: 0 !important;
    }
  }
}
