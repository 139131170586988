@use "../../../../assets/styles/shared" as *;

.tag-apply {
  .name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.5rem;
    padding-top: 3px ;
  }

  .bi::before {
    vertical-align: -6px !important;
  }
}
