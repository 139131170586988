@use "../../../assets/styles/shared" as *;

.filters-applied {
  .btn-container {
    padding: .2rem .5rem .1rem 1rem !important;
    height: 2rem;
    max-width: 12rem;

    .name {
      width: calc(100% - 28px);
      padding: .4rem 0;
      margin: 0;
    }

    i {
      font-size: 1.5rem;
      line-height: 1rem;
    }

    .bi-x::before {
      vertical-align: -5px;
    }
  }

  .link-btn {
    margin-top: -.8rem;
    font-size: .9rem;
  }

  @include media-breakpoint-down(lg) {
    border-top: 1px solid $hue-grey-medium;
    .link-btn {
      margin-top: -.5rem;
    }
  }
}
