@use "../../../../assets/styles/shared" as *;

.paging {


  .page-link {
    padding: 0 !important;
    min-width: 3rem;
    height: 1.9rem;
  }

  input {
    &.float-top {
      position: relative;
      z-index: 100;
    }

    width: 3.5rem;
    font-size: .8rem;
    line-height: 24px;
  }

  ul, li {
    list-style: none !important;
  }

  span {
    padding: .4rem;
  }

  input {
    padding: .25rem !important;
  }
}


.how-to-search {
  img {
    position: relative;
    margin-top: -3px;
  }
}

