@use "../../../assets/styles/shared" as *;

@mixin lds-splines {
  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      transform: rotate(($i - 1) * 30deg);
      animation-delay: -1.1 + ($i - 1) *.1s;
    }
  }
}



.lds-spinner {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;

  div {
    transform-origin: 30px 30px;
    animation: lds-spinner 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 28px;
      width: 4px;
      height: 15px;
      border-radius: 25%;
      background: #a6a6a6;
    }

    @include lds-splines;
  }
}

.lds-spinner.small {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;

  div {
    transform-origin: 25px 25px;
    animation: lds-spinner 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 1px;
      left: 24px;
      width: 3px;
      height: 13px;
      border-radius: 25%;
      background: #a6a6a6;
    }

    @include lds-splines;
  }
}

.lds-spinner.tiny {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;

  div {
    transform-origin: 12px 12px;
    animation: lds-spinner 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 1px;
      left: 12px;
      width: 3px;
      height: 7px;
      border-radius: 25%;
      background: #a6a6a6;
    }

    @include lds-splines;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

