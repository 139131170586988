@use "../../../assets/styles/shared" as *;

.tag-note-modal {
  z-index: 1000;
  width: 26.5rem !important;

  h6 {
    font-size: .9rem !important;
  }
  .close-btn {
    top: .5rem;
    right: .5rem;
  }

  .add-edit-dialog {
    left: 22rem;
    top: -.5rem;
  }
}
