@use "../../../assets/styles/shared" as *;

.viewer-toolbar {
  .vr {
    background-color: $hue-black;
    margin-top: .5rem;
    height: 1.5rem;
    width: 1px;
  }

  #input-pagenum {
    width: 3rem;
  }

  .tools{
    //margin-left: -1.8rem;

    i::before {
      font-size: 1.1rem;
    }
  }


  @include media-breakpoint-down(xxl) {
    .btn, .viewer-mode span, .viewer-mode .dropdown-toggle,  .dropdown-menu, .viewer-page-navigation, input   {
      font-size: .85rem !important;
    }
  }

}
