@use "../../../../assets/styles/shared" as *;

.back-search {
  padding: .25rem;
  width: fit-content;

  @include media-breakpoint-down(lg) {
    background-color: $hue-grey-lighter;
  }
}
