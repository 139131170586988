@use "../../../../assets/styles/shared" as *;

.tags-container {
  .doc-tag {
    width: fit-content;
    line-height: 1.5rem;
    max-width: 8rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
