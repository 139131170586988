@use "../../../assets/styles/shared" as *;
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

.my-library-dropdown {
  line-height: 1rem;
  width: fit-content;
  @include font-family(normal);

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      margin-left: -4rem;
    }
  }

  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      border: none !important;
      margin-top: 0 !important;
    }
  }
}
