@use "../../../../../assets/styles/shared" as *;

.card-menu-grid {

  margin: 0 auto;
  max-width:   $contentMaxWidth !important;
  text-align: center;

  a {
    text-decoration: none;
  }

  .cards {
    width: 100%;
    text-align: left;
  }

  .content-card {
    border: none;
    padding: 1.5rem;
    height: 8.3rem;
    max-width: 18rem;
    min-width: 14rem;
    width: 30%;
    margin: .8rem;
    display: inline-block;
    border-radius: .5rem;
    background-color: $hue-grey-lighter;
    cursor: pointer;
    position: relative;
    text-align: center;

    h5 {
      width: 75%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      color: $hue-black;
      text-decoration: none;
      margin: 0;
      p {
        @include font-family(medium);
        font-size: 1.2rem;
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .content-card {
      width: 40%;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(sm) {

    .container {
      padding: 0 !important;
    }

    .content-card {
      max-width: unset;
      width: calc(100% + 2rem);
      margin: .25rem -1rem !important;
      border-radius: 0 !important;
      border: 1px solid $hue-grey-medium;
    }
  }


}
