@use "../../../assets/styles/shared" as *;

.viewer-cite {
  .dropdown-toggle::after {
    float: right;
    margin-top: .5rem;
    margin-right: .25rem;
  }

  .cite-container {
    max-height: 15rem;
    overflow-y: auto;
  }
}
