@use "../../../assets/styles/shared" as *;

$thumbnail-width: 80px;
$thumbnail-height: 100px;

.viewer-pdf {
  .react-pdf__message {
    display: none;
  }

  .progress-container {
    margin-top: 10rem;

    .progress-bar {
      opacity: .8 !important;
    }
  }

  .thumbnail-container {
    -ms-flex: 0 0 calc($thumbnail-width + 3rem);
    flex: 0 0 calc($thumbnail-width + 3rem);
    height: $sz-tools-panel-height;
    overflow: hidden;
  }

  .thumbnail {
    width: $thumbnail-width;
    height: $thumbnail-height;
    margin: 0 auto 2rem !important;
    cursor: pointer;

   .active, .btn:active, .btn:hover {
      border-color: $bs-btn-hover-border-color;
    }
  }

  .overflow-scroll {
    height: $sz-tools-panel-height;
  }

  .spinner-container {
    margin-top: 4rem;
    left: calc(50% - 2rem);
    position: absolute;
    background-color: $hue-grey-medium;

    .lds-spinner div:after {
      background-color: $hue-white;
    }
  }

  .annotationLayer, .textLayer {
    display: none;
  }

  .text {
    .textLayer {
      position: relative;
    }

    canvas {
      display: none !important;
    }

    .annotationLayer, .textLayer {
      display: block  !important;
    }

    .annotationLayer, .textLayer :is(span, br) {
      color: $hue-black;
    }
  }

  .selected {
    position: absolute;
    z-index: 1000;
    background-color: #d5b0316b; //$hue-yellow;
    opacity: .5;
  }

  .react-pdf__Page {
    .react-pdf__Page__canvas {
      border: 1px solid $hue-grey-dark;
    }

    canvas {
      position: relative;
      z-index: 1;
    }

    .spinner {
      position: absolute;
      top: 40%;
      .lds-spinner div:after {
        background: $hue-white;
      }
    }

    &.loaded {
      .spinner {
        display: none;
      }
      canvas {
        visibility: visible !important;
      }
    }
  }

  .react-pdf__Thumbnail {
    canvas {
      max-width: 80px;
    }
  }

  .ocr-view {
    overflow-x: hidden !important;
  }

  .ocr-text {
    white-space: pre;
  }

}
