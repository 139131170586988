@use "../../../assets/styles/shared" as *;

.viewer-records {

  .navigation, .cards {
    margin: 0 1rem;
  }

  .overflow-scroll {
    height: calc($sz-tools-panel-height - 4.6rem);
  }

  .viewer-card-wrapper {
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $hue-blue-2;
      border-radius: 0.375rem;;
    }

    &.focus, &.active {
      border-color: var(--bs-btn-hover-border-color);
      border: 0 !important;
      box-shadow: var(--bs-btn-focus-box-shadow);
    }
  }

  .nav-item {
    a {
      padding: 6px 9px 5px;
    }
  }

  @include media-breakpoint-down(lg) {
    font-size: .9rem;
    justify-content: space-between;

    .list-group-item:first-child {
      border-top-left-radius: .6rem;
      border-top-right-radius: .6rem;
    }

    .list-group-item:last-child {
      border-bottom-right-radius: .6rem;
      border-bottom-left-radius: .6rem;
    }

    .list-group-item {
      border-radius: .6rem;
      cursor: pointer;
      position: relative;
      background-color: $hue-grey-lighter;
      line-height: 1.1rem;

      &.active {
        background-color: $hue-grey-darker;
        border-color: $hue-grey-dark;
        color: $hue-white;
      }

      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      width: 46.5%;
      display: inline-block;
      height: 4.5rem;
      text-align: center;
      vertical-align: top;
      margin-bottom: 1rem;

      &:nth-child(2n) {
        margin-left: 7%;
      }
    }
  }
}
