@use "../../../assets/styles/shared" as *;

.registration-cmp{
  .registration {
    .label {
      font-size: .9rem !important;
    }
  }

  .error {
    color: red;
  }
}
