@use "../../../assets/styles/shared" as *;

.viewer-panel {
  width: calc(100% - 1rem); /* take into account for collapse button */

  .media-preview a {
    outline: none !important;

    &:active, &:hover, &:focus {
      outline: none !important;
      border: 0 !important;
      box-shadow: none !important;
    }
  }

  .public-btns .btn {
    background-color: transparent !important;
    border: 0;
    color: $hue-blue !important;
    &.active {
      background-color: $hue-blue !important;
      color: $hue-white !important;
    }
  }
}
