@use "../../../assets/styles/shared" as *;

.document-card {

  .doc-tags {
    button {
      max-width: 11rem;
    }
  }


  sel {
    padding: 1px 2px;
    background-color: $hue-highlight;
  }

  ol {
    list-style: none;
  }


  .cited {
    a {
      cursor: pointer;
      text-decoration: underline !important;
    }
  }

  i {
    //font-size: 1.2rem;
    position: relative;
  }

  label {
    a {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical
    }
  }

  .header-link {
    margin-top: 3px;
  }

  .doc-content {
    .col-lg-2, .col-lg-10 {
      margin: 5px 0;
    }
  }

  .expand-btn, .doc-view-btn {
    border: 0 !important;

    &:hover, &:focus, &:active, &:visited, &:focus-within, &:focus-visible {
      border: 0 !important;
    }
  }

  img {
    max-height: 6rem;
  }

  .excerpt {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical
  }

  .expand {
    .btn:focus {
      box-shadow: none;
    }
  }

  .notes {
    .note-input {
      width: calc(100% - 4rem);
    }
  }

  .tags-applied {
    margin-top: -1rem;
    .tags-container {
      margin-bottom: .5rem;
    }
  }

  @include media-breakpoint-down(lg) {
    .entry-row {
      flex-wrap: nowrap;
      width: 60%;
    }
  }

}
