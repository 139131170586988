@use "../../../assets/styles/shared" as *;

.password-field {
  button {
    top: 1.8rem;
    right: .15rem;
  }

  input {
    padding-right: 2.4rem !important;
  }

  i::before {
    margin-top: 5px;
  }

  .form-control.is-invalid,
  .form-control:invalid,
  .form-control.is-valid,
  .form-control:valid  {
    background-position: right 2.5rem center !important;
  }
}
