@use "../../../../assets/styles/shared" as *;

.tag-remove {
  .tag {
    width: fit-content;
  }
  hr {
    margin: 0 -1rem;
  }
}
