@use "../../../assets/styles/shared" as *;

.notify-modal {
  width: 25rem !important;
  height: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  .modal-dialog {
    margin: 0 !important;
  }
}

