@use "../../../assets/styles/shared" as *;

.viewer-tools {
  border-bottom-left-radius: 1rem;
  background-color: $hue-grey-lighter; //bg-light

  .nav-item {
    white-space: nowrap;
  }

  .active {
    @include font-family(bold);
    background-color: $hue-blue-lighter;
    border-right: 5px solid $hue-blue;
  }

  .accordion-button {
    @include font-family(normal);
    padding: .4rem 1rem;
  }

  .accordion-body a {
    padding-left: 1.5rem !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2300000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  }

  .accordion, .accordion-item, .accordion-button {
    background-color: transparent !important;
  }

  .accordion-button .collapsed  {
    color: $hue-black !important;
  }

  .accordion-button:not(.collapsed) {
    color: $hue-black !important;
    background-color: transparent !important;
    box-shadow: none;
  }

  .accordion-button:focus {
    border: 0 !important;
    outline: 0;
    box-shadow: none;
  }

  @include media-breakpoint-down(lg) {

    background-color: $hue-white !important;
    border-radius: 0;

    i {
      font-size: 1.05rem;
    }

    .nav {
      margin: .5rem 1rem;
      flex-direction: row !important;
      justify-content: space-between;

      .nav-item {
        width: calc(50% - .5rem);
        margin-bottom: .5rem;

        a {
          padding: 2rem calc(50% - 4rem) !important;
          background: $hue-grey-lighter;
          text-align: center;
        }
      }
    }
  }

  @include media-breakpoint-down(xxl) {
    .btn, .nav-link {
      font-size: .85rem !important;
    }
  }
}
