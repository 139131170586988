@use "../../../../../assets/styles/shared" as *;

.searchable-table {
  .table {
    .num-col {
      flex: 0 !important;
      padding: 0 1.5rem ;
    }

    .num-col, .content-col {
      font-size: .9rem;
    }
    .container {
      padding-left: .8rem !important;
      padding-right: .8rem !important;
    }

    .scroll-container {
      overflow: auto;
      max-height: 30rem;
      margin: 0 -1rem;
    }
  }

  .toolbar {
    display: flex;
  }

  /* special cases */
  &.slug_how-to-search {
    .how-to-search {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .toolbar, .paging {
      display: block;
    }

  }

}
