@use "../../../../assets/styles/shared" as *;

.page-view {
  min-height: 40rem;

  .header, h1 {
    font-size: 2.1rem;
    @include font-family(bold);
  }

  .content, .post .post-layout {
    max-width: 1024px;
  }

  .post-wrapper {
    margin-top: -1rem;
  }
  .card-grid {
    padding: 0 !important;
  }

  .btn-container {
    margin: 0 auto;
    max-width: 1024px;

    button {
      margin-left: -.75rem;
      margin-bottom: .5rem;
    }
  }

  .back-btn {
    margin-left: .75rem;

    img {
      position: relative;
      top: -2px;
    }
  }

  .secondary-page {
    margin: 0 auto;
  }

  .collection-page {
    h1 {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 0 !important;

    .back-btn {
      margin-left: 0 !important;
    }

    .grid-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 1rem;
    }

    .document-count {

    }
  }
}
