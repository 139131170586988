$hue-white: #ffffff;
$hue-black: #000000;
$hue-grey-light: #f1f0f0;
$hue-grey: #bebebe;
$hue-grey-dark: #9d9d9d;
$hue-grey-darker: rgb(107, 107, 107);
$hue-grey-darkest: rgb(73, 73, 73);

//757575

$hue-grey-1: rgb(248, 249, 250);
$hue-grey-medium: rgb(211, 212, 213);
$hue-grey-lighter: rgb(238, 238, 238);
$hue-grey-light2: #F0F2F6;

$hue-navy-blue-100: rgba(5, 32, 73, 1);
$hue-blue: rgb(59, 85, 230);
$hue-blue-light: rgb(240, 242, 247);
$hue-blue-lighter: rgb(233, 241, 254);
$hue-blue-2: rgb(102, 145, 182);

$hue-blue-medium: #0D3888;
$hue-blue-medium2: #3C55E6;
$hue-blue-underline: #0056D5;
$hue-grey-medium3: #7F7F7F;
$hue-brown: #404142;
$hue-black-text: #212529;
$hue-grey-text: #717171;

$hue-brown-2: #808080;


$hue-red: #cb0101;
$hue-yellow: #d5b031;
$hue-highlight: #ffe670;

/* custom toasts colors */
$hue-success-bg: rgb(214,241,219);
$hue-success-text: rgb(0, 72, 59);
$hue-success-border: rgb(0, 174, 105);

/* Components*/
$nav-underline: $hue-blue;


/* sizing */
$sz-tools-panel-height: 800px;

/* bootstrap */
$bs-light-rgb: rgb(248, 249, 250);
$bs-btn-hover-border-color: #0a58ca;

/* max widths */
$contentMaxWidth: 65rem;

$pastel-pink: #fae7eb;
$pastel-purple: #e0d4e7;
$pastel-blue:#dbeef7;
$pastel-orange: #ffdab9;
$pastel-beige:#f1cebe;
$pastel-green:#a9cDD7;
$pastel-turquoise:#d0edef;
