@use "../../../../assets/styles/shared" as *;

.settings-view {
  .field-width {
    max-width: 20rem;
  }

  .form-check .form-check-input {
    font-size: 1.2rem;
    margin-left: -1.5rem;
    margin-top: -1px;
  }

  .notifications {
    input {
      margin-top: 1px !important;
    }
    label {
      margin-left: 2px !important;
    }
  }
}
