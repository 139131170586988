@use "../../../assets/styles/shared" as *;

.media-preview {
  i {
    line-height: 4rem;
    width: auto;
  }
  .caption {
    font-size: 1.5rem;
  }



  .confidential, .privileged, .copyright {
    color: $hue-red;
    i {
      line-height: 5rem;
      font-size: 4rem;
    }

    .caption {
      font-size: .8rem;
    }
  }

  .btn-container {
    width: fit-content;
  }

  .btn:hover {
    border: 1px solid $bs-btn-hover-border-color !important;
  }

  .btn-link {
    text-align: center;
    &:hover {
      background-color: transparent;
      text-decoration: underline !important;
      border: 0 !important;
    }

    &:focus, &:active {
      box-shadow: none;
      text-decoration: underline !important;
      border: 0 !important;
    }
  }

  .preview {
    .bi {
      font-size: 3.5rem;
    }

    &.viewer {
      min-width: 3.5rem;
      .bi {
        font-size: 1.8rem;
        line-height: 1rem;
      }
      .caption {
        font-size: .55rem;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .preview {
      .bi {
        font-size: 2rem;
        line-height: 2rem !important;
      }

      .caption {
        font-size: .8rem !important;
      }
    }
  }
}
