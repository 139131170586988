@use "../../../assets/styles/shared" as *;

.viewer-view {
  @include media-breakpoint-down(lg) {

    h5 {
      font-size: 1.5rem;
      @include font-family(bold)
    }

    h1, .tool-title {
      font-size: .875rem;
      @include font-family(normal, italic);
      color: $hue-grey-dark !important;
    }
    .viewer-tools .active {
      border: none !important;
    }
    .tool-title {
      @include font-family(bold, italic)
    }

    .subheader {
      border-radius: 0;
      margin-top: -.5rem;
      padding: 2rem .5rem .25rem !important;
      h5 {
        &.mobile-menu {
          font-size: 1.1rem;
          @include font-family(bold);
          color: $hue-black-text !important;
        }
      }
      h1 {
        &.mobile-menu {
          @include font-family(normal);
          font-size: 1.5rem;
          color: $hue-black-text !important;
        }
      }

    }
  }

  @include media-breakpoint-down(sm) {
    .inner {
      padding-right: .25rem !important;
    }
  }
}
