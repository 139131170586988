@use "../../../assets/styles/shared" as *;

.page-tools {

  .record-select {
    .dropdown-toggle::after {
      margin-left: .5rem;
    }
  }
}
