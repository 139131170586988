@use "../../../../../../assets/styles/shared" as *;

.document-count {
  position: relative;
  display: inline-block;

  margin-right: 2px;
  margin-bottom: 0 !important;

  &.loading {
    min-width: 2rem;
  }
  .spinner {
    position: absolute;
    margin: -1rem .5rem 0;
    div {
      margin-bottom: 0;
    }
  }
}

.ellipses_animated:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1000ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}
