@use "../../../../assets/styles/shared" as *;

.tags-modal {
  .close-btn {
    z-index: 1000;
    right: -1rem;
  }

  .add-tag-btn {
    padding: 3px 1px 1px !important;
    line-height: 1rem;

    .bi::before {
      padding: 0 2px;
      line-height: .8 !important;
      font-size: .8rem;
      font-weight: 600 !important;
      vertical-align: -2px !important;
    }

    &:hover {
      border: 1px solid $hue-blue !important;
    }
  }

  .add-edit-dialog {
    margin: .5rem 0 0;
    z-index: 2000;
    width: 23rem;
  }
}
